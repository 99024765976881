<template>
  <Datatable
    :total="getSimpleOffices.meta.page.total"
    :perPage="getSimpleOffices.meta.page.perPage"
    :currentPage="getSimpleOffices.meta.page.currentPage"
    @pagechanged="onPageChange"
    :meta="getSimpleOffices.meta"
    cursor
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode Kantor
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode / Nama Upline
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode / Nama Stockist
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode / Nama SC
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            No. Rek
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Alamat / Telp
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <tbody>
        <tr
          class="bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getSimpleOffices.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
        >
          <td
            @click="onClickDetailOffice(data)"
            class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            <div>
              <p class="font-bold">
                {{ data.attributes.code }}
              </p>
              <p class="text-xs">
                {{ data.attributes.name }}
              </p>
            </div>
          </td>
          <td
            @click="onClickDetailOffice(data)"
            class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            <div>
              <p class="font-bold">
                {{ getUpline(data, 'referred_by')?.code ?? '-' }}
              </p>
              <p class="text-xs">
                {{ getUpline(data, 'referred_by')?.name ?? '-' }}
              </p>
            </div>
          </td>
          <td
            @click="onClickDetailOffice(data)"
            class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            <div>
              <p class="font-bold">
                {{ getDownline(data, 'promoting_to_stockist')?.code ?? '-' }}
              </p>
              <p class="text-xs">
                {{ getDownline(data, 'promoting_to_stockist')?.name ?? '-' }}
              </p>
            </div>
          </td>
          <td
            @click="onClickDetailOffice(data)"
            class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            <div>
              <p class="font-bold">
                {{ getDownline(data, 'promoting_to_sc')?.code ?? '-' }}
              </p>
              <p class="text-xs">
                {{ getDownline(data, 'promoting_to_sc')?.name ?? '-' }}
              </p>
            </div>
          </td>
          <td
            @click="onClickDetailOffice(data)"
            class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            {{ getOfficeIncluded(data).attributes.bank_account_number ?? '-' }}
          </td>
          <td
            @click="onClickDetailOffice(data)"
            class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            {{ getOfficeIncluded(data)?.address ?? '-' }}
            <p class="text-xs">
              {{ getOfficeIncluded(data)?.phone ?? '-' }}
            </p>
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <button
              v-if="!getOfficeIncluded(data).attributes.is_promoted"
              @click="onPromote(data, 'promoteToStockist')"
              class="rounded-xl bg-green-100 px-4 py-1 text-sm text-green-800"
            >
              Promosi ke Stockist
            </button>
            <button
              v-else
              class="rounded-xl bg-blue-100 px-4 py-1 text-sm text-blue-800"
            >
              {{ getOfficeIncluded(data).attributes.promoted_by }}
            </button>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MitraUsahaTable',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getLoading: 'offices/getLoading',
      getError: 'offices/getError',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficeLoading: 'simple_offices/getLoading',
    }),
  },
  components: {},
  methods: {
    onPageChange(val) {
      this.$emit('page-change', val);
    },
    onClickDetailOffice(val) {
      this.$emit('click-row', val);
    },
    getUpline(simpleOffice, type) {
      const office = this.getOfficeIncluded(simpleOffice);

      return (
        office.attributes.upline_histories?.find(
          (upline) => upline.type === type
        ) ?? {}
      );
    },
    getDownline(simpleOffice, type) {
      const office = this.getOfficeIncluded(simpleOffice);

      return (
        office.attributes.downline_histories?.find(
          (downline) => downline.type === type
        ) ?? {}
      );
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSimpleOffices,
        simpleOffice.relationships.office.data.id
      );
    },
    onPromote(data, type) {
      this.$emit('promote', { data, type });
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
